.container {
  background-color: var(--azul-medio);
  margin:0 20% 0 20%;
  padding: 3rem 0 3rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  border-radius: 80px;
}
.container:hover {
  background-color: #334158;
  transition: 0.3s;
}

.titulo {
  font-size: 50px;
  color: var(--verde);
}

.tags {
  display: flex;
  gap: 5.2rem;
  flex-direction: row;
}

.tag {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  border-bottom: 1px solid var(--borda);
}
.tag:hover {
  filter: drop-shadow(0 0 2em #dadcffaa);
}

.img {
  width: 120px;
  height: 120px;
  padding-bottom: 10px;
}

.legenda {
  text-transform: uppercase;
}

@media (max-width: 1280px) {
  .container {
    padding: 5% 10% 5% 10%;
    margin: 0 10% 0 10%;
    border-radius: 40px;
  }
  .titulo {
    font-size: 40px;
  }
  .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.2rem;
    align-items: center;
    justify-content: center;
  }
  .tag {
    height: 120px;
    width: 120px;
    gap: 0.5rem;
  }
  .img {
    width: 64px;
    height: 64px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 5% 10% 5% 10%;
    margin: 0 5% 0 5%;
    border-radius:20px;
    
  }
  .titulo {
    font-size: 40px;
  }
  .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.2rem;
    align-items: center;
    justify-content: center;
  }
  .tag {
    height: 120px;
    width: 120px;
    gap: 0.5rem;
  }
  .img {
    width: 64px;
    height: 64px;
  }
}