.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin: 4rem 21rem 0rem;
}
.titulo{
  display: None;
}

@media (max-width: 1100px) {
  .grid {
    margin: 4rem 10rem 0rem;
    gap: 1rem;
  }
  .titulo{
    display: block;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-bottom: -20px;
    margin-top:40px
  }
}
