.ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  gap: 8px;
}

.h3 {
  display: inline-block;
  background-color: transparent;
  font-weight: 300;
  margin-bottom: 5px;
}

.tag {
  background-color: var(--azul-escuro);
  border-radius: 8px;
  padding: 10px 10px 6px 10px;
}

.tag img {
  height: 25px;
  width: 25px;
  background-color: transparent;
}
