.post {
  text-align: center;
  background-color: var(--azul-medio);
  width: 282px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 1.5rem;
  transition: transform 0.2s;
}

.post:hover {
  transform: scale(0.95);
}

.capa {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 125px;
  object-fit: cover;
}

.titulo {
  font-family: var(--fonte-secundaria);
  background-color: transparent;
  color: var(--verde);
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-transform: capitalize;
  margin: 1.5rem 0 1.75rem;
}

@media (max-width: 1100px) {
  .post {
    width: 336px;
  }
  .titulo {
    font-weight: 600;
  }
}
