

.navegacao {
  height: 112px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 5rem;
  column-gap: 0.5rem;
  background-color: var(--header-BG);
}

.navegacao a {
  color: var(--branco);
  font-size: 20px;
  text-decoration: none;
  padding: 0.5em 1em;
  transition: 0.5s;
  background-color: transparent;
  border-radius: 50px;
}

.navegacao a:hover {
  background-color: var(--azul-claro);
  border-radius: 50px;
}

header .navbtn{
  padding:5px;
  font-size: 2rem;
  cursor: pointer;
  background:transparent;
  border: none;
  outline: none;
  color: var(--branco);
  display: none;
  opacity:0;
}

header .titulo{
  display:none;
}

@media (max-width: 744px) {
  .navegacao {
    height: 72px;
    justify-content: space-between;
  }
  header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.4rem;
    
  }
  header .navbtn{
    display: flex;
    position: absolute;
    top:2rem;
    right:2rem;
    opacity:1; 
  }

  header .titulo{
  display: block;
  font-size: 24px;
  font-family:var(--fonte-titulo);
  z-index: 1;
  }

  header .navegacao{
    position:fixed;
    top:0;
    left:0;
    padding:0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;  
    background-color: var(--nav);
    transition: 1s;
    transform: translateY(-100vh);
    z-index:99;
  }

  header .responsiveNav{
    transform:none;
  }

  header .navbtnClose{
    position: absolute;
    top:2rem;
    right:2rem;

  }
  nav .links{
    font-size: 1.5rem
  }
  
}
