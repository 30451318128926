.card {
    background-color: var(--azul-medio);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 7.5rem 35% 7.5rem 35%;
    padding:3rem 5rem 3rem 5rem;
    border-radius: 60px;
}

.card h1 {
    margin-top: -30px;
    font-size: 60px;
    margin-bottom: 20px;
    color: var(--verde);
    
}

.card p {
    font-size: 16px;
    line-height: 1.5;
    
}

.card div{
    display: flex;
    flex-direction: row;
    gap: 20px
}

.contato{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #334158;
    padding: 12px 19px 12px 19px;
    border-radius: 10px;
    margin: 10px 0 10px 0
}

.contato:hover{
    background: #2c3e4f;
    transition: 0.3s;
}

.contato img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

@media (max-width: 1280px) {
    .card {
        margin: 7.5rem 10% 7.5rem 10%;
        padding:3rem 5rem 3rem 5rem;
        border-radius: 40px;
    }
    .card h1 {
        font-size: 40px;
    }
    .card p {
        font-size: 16px;
    }
    .contato img{
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 720px){
    .card {
        margin: 7.5rem 5% 7.5rem 5%;
        padding:3rem 5rem 3rem 5rem;
        border-radius: 40px;
    }
    .card h1 {
        font-size: 44px;
    }
    .card p {
        font-size: 20px;
    }
    .contato img{
        width: 64px;
        height: 64px;
    }
    .card div{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}