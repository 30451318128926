.container {
  display:flex;
  padding: 0;
  flex-direction:column;
  gap:2rem;  
}
.titulo{
  display: None;
}

@media (max-width: 768px) {
  .container{
  display: flex;
  flex-direction: column;
  gap:2rem;
  }
  .titulo{
    display: block;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    margin-top:40px
  }
}
