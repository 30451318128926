.container__apresentacao {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 8em;
  margin: 3em auto 14em;
}

.apresentacao__eu {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.apresentacao__ola {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.ola__texto {
  font-size: 32px;
  z-index: 1;
}

.apresentacao__nome {
  font-size: 80px;
  font-weight: 700;
  z-index: 1;
}

.apresentacao__funcao {
  font-size: 32px;
  color: var(--verde);
  z-index: 1;
}
.apresentacao__imagem {
  height: 30em;
  background-color: var(--verde);
  border-radius: 400px;
  transition: 0.5s;
  opacity: 0.7;
}
.apresentacao__imagem:hover {
  transition: 0.5s;
  opacity: 1;
}

@media (max-width: 1100px) {
}

@media (max-width: 744px) {
  .apresentacao__imagem {
    position:fixed;
    height: 20em;
    margin-top: 4.5rem;
    background-color: var(--verde);
    border-radius: 400px;
    transition: 0.5s;
    opacity: 0.3;
    z-index: -1;
    margin:30% auto 0%;
  }

  .apresentacao__imagem:hover {
    transition: 0.5s;
    opacity: 0.3;
    
  }
}
