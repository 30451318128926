* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  color: var(--branco);
}

body {
  background-color: var(--azul-escuro);
  font-size: 16px;
}
:root {
  /* Fontes */
  --fonte-principal: "Roboto";
  --fonte-secundaria: "Playfair Display", sans-serif;
  --fonte-titulo:"Quicksand", sans-serif;

  /* Cores */
  --header-BG: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 100%
  );
  --azul-claro: #2c66c3;
  --azul-medio: #293548;
  --azul-escuro: #1e293b;
  --nav:#0c1d38fa;
  --verde: #2cb67d;
  --cinza: #444444;
  --cinza-claro: #bbbbbb;
  --branco: #ffffff;
  --branco-acinzentado: #bfbfbf;
  --preto-azulado: #111827;
  --preto: #161b22;
  --preto-claro: #14191f;
  --borda: #ffffff11;

  font-family: var(--fonte-principal);
}

#root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  min-height: 100vh;
}

a,
button,
input,
textarea {
  font: inherit;
  color: inherit;
}

button {
  background-color: transparent;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}
